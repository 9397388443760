$primary: #07679c;
$primary1: #839dd1;
$sec: #eb5160;
$sec1: #b60011;
$lt-primary: #f4f8ff;
$lt-blue: #f1f7ff;
$Blue: #1850a8;
$Blue1: #0082ca;
$lt-blue: #e9f0ff;
$lt-blue1: #cde2fe;
$lt-blue2: #f4f8ff;
$lt-blue3: #ecfaff;
$gray: #787b7f;
$gray1: #262626;
$gray2: #353535;
$gray3: #e5e5e5;
$gray4: #cbcbcb;
$white: #fff;
$black: #000;
$orange: #ff9a34;
$lt-orange: #fff3e0;
$red: #f82a2a;
$pink: #fb457d;
$pink1: #d9446f;
$greens: #009688;
$green1: #00d53c;
$soft-gray: #cacaca;
$soft-gray1: #efefef;
$yellow: #ffe501;
$dark-gray: #68738d;
$font-blue: #344767;
$icon-blue: #49d7f4;
$back-blue: #e9f0ff;
$soft-green: #eafbeb;
$soft-primary: #b2c3e3;
$soft-red: #f5e3e6;
$inr: ₹;

.err {
  // color: #ff0000;
  color: #df2a2a;
}
.primary {
  color: $primary !important;
}
.primary1 {
  color: $primary1 !important;
}
.bg-soft-red {
  background-color: $soft-red !important;
}
.yellow {
  color: $yellow !important;
}
.dark-gray {
  color: $dark-gray !important;
}
.bg-soft-green {
  background-color: $soft-green !important;
}
.bg-soft-primary {
  background-color: $soft-primary !important;
}
.font-blue {
  color: $font-blue !important;
}
.bg-yellow {
  background-color: $yellow !important;
}
.sec {
  color: $sec !important;
}
.back-blue {
  color: $back-blue !important;
}
.bg-back-blue {
  background-color: $back-blue !important;
}
.bg-lt-blue2 {
  background-color: $lt-blue2 !important ;
}

.icon-blue {
  color: $icon-blue !important;
}

.bg-icon-blue {
  background-color: $icon-blue !important;
}

.sec1 {
  color: $sec1;
}
.orange {
  color: $orange;
}
.lt-orange {
  color: $lt-orange;
}
.blue1 {
  color: $Blue1 !important;
}
.soft-gray {
  color: $soft-gray !important;
}
.pinks {
  color: $pink !important;
}
.pink1 {
  color: $pink1 !important;
}

.greens {
  color: $greens !important;
}
.green1 {
  color: $green1 !important;
}

.lt-Blue {
  color: $lt-blue;
}

.lt-blue1 {
  color: $lt-blue1;
}

.lt-blue2 {
  color: $lt-blue2;
}

.white {
  color: $white !important;
}

.gray {
  color: $gray;
}
.gray1 {
  color: $gray1;
}
.gray3 {
  color: $gray3;
}
.gray4 {
  color: $gray4;
}

.bg-primar {
  background-color: $primary !important;
}

.bg-lt-primary {
  background-color: $lt-primary !important;
}

.bg-primary1 {
  background-color: $primary1 !important;
}

.bg-sec {
  background-color: $sec !important;
}

.bg-sec1 {
  background-color: $sec1 !important;
}
.bg-gradient {
  background: linear-gradient($primary, $primary1) !important;
}
.bg-bg-dark {
  background-color: $black !important;
}

.bg-darkBlue {
  background-color: $Blue !important;
}
.bg-blue1 {
  background-color: $Blue1 !important;
}

.bg-pinks {
  background-color: $pink !important;
}
.bg-red {
  background-color: $red !important;
}

.bg-pink1 {
  background-color: $pink1 !important;
}
.bg-greens {
  background-color: $greens !important;
}

.bg-green1 {
  background-color: $green1 !important;
}

.bg-lt-blue {
  background-color: $lt-blue !important;
}

.bg-lt-blue1 {
  background-color: $lt-blue1 !important;
}
.bg-lt-blue2 {
  background-color: $lt-blue2 !important;
}
.bg-gray {
  background-color: $gray !important;
}

.bg-orange {
  background-color: $orange;
}
.bg-lt-orange {
  background-color: $lt-orange;
}
.bg-gray1 {
  background-color: $gray1;
}
.bg-gray2 {
  background-color: $gray2 !important;
}

.bg-soft-gray1 {
  background-color: $soft-gray1 !important;
}

.bg-gray3 {
  background-color: $gray3 !important;
}

.bg-gray4 {
  background-color: $gray4 !important;
}

.bg-white {
  background-color: $white;
}

.border-primar {
  border: 2px solid $primary;
}
//

$pending: #f0bc10;
$lt-pending: #fdf4cd;
$design: #f4a42b;
$lt-design: #f9e5cc;
$production: #6650a5;
$lt-production: #dad2e9;
$ready: #0f4f8d;
$lt-ready: #d0e3f4;
$approved: #67a84a;
$lt-approved: #d8ead2;

.pending {
  color: $pending;
}

.lt-pending {
  color: $lt-pending;
}

.design {
  color: $design;
}

.lt-design {
  color: $lt-design;
}

.ready {
  color: $production;
}

.lt-ready {
  color: $lt-production;
}

.ready {
  color: $ready;
}

.lt-ready {
  color: $lt-ready;
}

.approved {
  color: $approved;
}

.lt-approved {
  color: $lt-approved;
}

.bg-pending {
  background-color: $pending;
}

.bg-lt-pending {
  background-color: $lt-pending;
}

.bg-design {
  background-color: $design;
}

.bg-soft-gray {
  background-color: $soft-gray;
}

.bg-lt-design {
  background-color: $lt-design;
}

.bg-ready {
  background-color: $production;
}

.bg-lt-ready {
  background-color: $lt-production;
}

.bg-ready {
  background-color: $ready;
}

.bg-lt-ready {
  background-color: $lt-ready;
}

.bg-approved {
  background-color: $approved;
}

.lt-approved {
  color: $lt-approved;
}
.text-Blue {
  color: $Blue;
}
