@import "../constatnt/fonts.scss";
@import "../constatnt/color.scss";

body,
html {
  font-family: "f1";
  margin: 0;
  padding: 0;
  // overflow-x: hidden;
}

body {
  background-color: $lt-blue2 !important;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgb(230, 230, 230);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
}

.out-left {
  z-index: 0;
}

.dashboard {
  width: 100%;
  display: flex;
  height: 100vh;

  .navBar {
    width: 18%;
    display: block;
    min-height: 100vh;
    overflow-x: scroll;
    padding-bottom: 30px;
    justify-content: center;
    box-shadow: rgba(189, 189, 190, 0.25) 0px 0px 0px 0px inset,
      rgba(164, 163, 163, 0.3) 0px 0px 36px 0px inset;
  }

  .dashRightView {
    height: 100vh;
    width: 82%;
    background-color: white;
    padding: 50px;
    overflow: scroll;
  }
}

.searchIcon {
  height: 25px;
  width: 25px;
}

.bgColorView1 {
  height: 75px;
  width: 10px;
  background-color: #ecf1ff;
  border-radius: 10px;
  position: relative;
  margin-right: 4px;
}

.bgColorView3 {
  width: 10px;
  border-radius: 10px;
  bottom: 0px;
  position: absolute;
  background-color: #1be6df;
}

.bgColorView2 {
  // height: 50px;
  width: 10px;
  border-radius: 10px;
  bottom: 0px;
  position: absolute;
  background: linear-gradient(#1be6df, #bff9f7);
}

.dailyStatus {
  border: white 1px solid;
}

.searchField {
  border: $soft-gray1 1px solid;
  border-radius: 10px;
  background: #f1f7fc;
  box-shadow: 7px 7px 15px -1px #d1d6eb;
  -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb;
  margin-bottom: 40px;
  padding: 20px 15px;
}

.dotColor {
  height: 5px;
  width: 5px;
}

.imgDashboard {
  width: 75px;
  height: 55px;
}

.searchManImg {
  width: 30px;
  height: 25px;
}

.searchManImgMan {
  width: 25px;
  height: 25px;
  top: 8px;
  left: 10px;
}

.searchiConImg {
  width: 23px;
  height: 23px;
  margin-top: 8px;
}

.buttnDesign {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.wholeDesign {
  width: 1000px;
}

.imgDashboard1 {
  width: 55px;
  height: 40px;
}

.commonSpaceImage {
  margin-left: 50px;
}

.commonSpaceImage1 {
  margin-left: 0px;
}

.commonSpaceName {
  margin-left: 100px;
}

.commonSpaceName1 {
  margin-left: 70px;
}

.commonSpacemail {
  margin-left: 140px;
}

.commonStatus {
  margin-left: 135px;
}

.commonSpaceOptions {
  margin-left: 125px;
}

.commonSpacemail1 {
  margin-left: 100px;
}

.adminCreatebtn {
  display: flex;
  justify-content: flex-end;
}

.reciSpace {
  margin-left: 15px;
}

.paymentSpace {
  margin-left: 25px;
}

.proImg {
  height: 150px;
  width: 150px;
}

.editBtn {
  border: $primary 1px solid;
}

.editBtnSelect {
  border: $primary 1px solid;
  padding: 10px;
}

.wholeDesignDash {
  width: 1090px;
}

.wholeCustomerDet {
  width: 1000px;
}

.wholeDesignDash1 {
  width: 1080px;
}

.wholeDesignCustomerDeco {
  width: 1100px;
}

.wholeDesignposBills {
  width: 1350px;
}

.dashTotalDesChart {
  border: $soft-gray1 1px solid;
  border-radius: 10px;
  background: #f1f7fc;
  box-shadow: 7px 7px 15px -1px #d1d6eb;
  -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb;
  border-radius: 15px;
  margin-bottom: 40px;
  padding: 38px 15px;
}

.dashTotalDesMonths {
  border: $soft-gray1 1px solid;
  border-radius: 10px;
  background: #f1f7fc;
  box-shadow: 7px 7px 15px -1px #d1d6eb;
  -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb;
  border-radius: 15px;
  margin-bottom: 40px;
  padding: 20px 15px;
}

// .viewBoxDes {
//   box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
//     inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
//     4px 5px 6px 1px rgba(149, 192, 234, 0.4);
//   -webkit-box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
//     inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
//     4px 5px 6px 1px rgba(149, 192, 234, 0.4);
// }

.viewBoxDes {
  box-shadow: -5px -5px 15px 0px #fff, 5px 5px 15px 0px rgba(174, 174, 192, 0.4);
}

.viewBoxDesSign {
  box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  -webkit-box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  position: absolute;
  top: 20%;
  right: 38%;
}

.viewBoxDesSign1 {
  box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  -webkit-box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  position: absolute;
  top: 30%;
  right: 38%;
}

.totalSiteDesWid {
  width: 70%;
}

.commonSpacemailROle {
  margin-left: 250px;
}

.commonSpacemailStatus {
  margin-left: 280px;
}

.commonSpacemailOptions {
  margin-left: 310px;
}

.addbtn {
  -webkit-box-shadow: inset -10px -10px 8px -1px $primary,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
  // 3px 5px 3px 1px $primary;
  padding: 9px 25px 9px 25px !important;
}

.addbtn2 {
  padding: 9px 25px 9px 25px !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-color: $primary1 !important;
}

.addbtn1 {
  -webkit-box-shadow: inset -10px -10px 8px -1px $primary,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
  // 3px 5px 3px 1px $primary;
  // padding: 9px 2px 9px 25px !important;
  width: 32px;
  height: 32px;
}

.eyeiConDesign {
  padding: 3px 5px 3px 5px;
  background-color: white;
  border-radius: 10px;
  border: 0;
  box-shadow: inset -5px -15px 20px 1px $Blue1, inset -7px -7px 3px 0px #fff,
    inset -5px -5px 9px 5px #fff, inset -5px -5px 9px 1px $icon-blue;
}

.eyeiConDesign1 {
  background-color: white;
  border-radius: 10px;
  border: 0;
  box-shadow: inset -5px -15px 20px 1px $orange, inset -7px -7px 3px 0px #fff,
    inset -5px -5px 9px 5px #fff, inset -5px -5px 9px 1px $orange;
}

.normalInput {
  border: $primary 1px solid;
  padding: 0px 0px 0px 40px;
}

.checkIcon {
  background-color: $primary;
  color: white;
}

.checkIcon1 {
  background-color: white;
  color: white;
  border: $primary1 1px solid;
}

.viewIconDes {
  width: 20px;
  height: 20px;
}

.toggleOnDes {
  width: 35px;
  height: 20px;
}

.toggleOnDes1 {
  width: 25px;
  height: 15px;
}

.viewDes {
  width: 20px;
  height: 16px;
}

.signInDes {
  width: 100%;
  height: 100vh;
}

.dashTotalDesTotalSites {
  border: $soft-gray1 1px solid;
  border-radius: 10px;
  background: #f1f7fc;
  box-shadow: 7px 7px 15px -1px #d1d6eb;
  -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb;
  border-radius: 15px;
  margin-bottom: 40px;
  padding: 15px 23px 15px 23px;
  align-items: center;
  justify-content: center;
  display: grid;
}

.dashTotalDesTotalSites1 {
  border: $soft-gray1 1px solid;
  border-radius: 10px;
  background: #f1f7fc;
  box-shadow: 7px 7px 15px -1px #d1d6eb;
  -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb;
  border-radius: 15px;
  margin-bottom: 40px;
  padding: 15px 23px 15px 23px;
  align-items: center;
  justify-content: center;
  display: grid;
}

.dashIcons {
  padding: 5px 90px 5px 90px;
}

.forgotPassword {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.visibleDes {
  position: absolute;
  right: 10px;
  bottom: 8px;
}

.signLeftDes {
  margin: 190px 0px 0px 100px;
  background-image: url("../../img/login_vector.webp");
  background-size: contain;
  background-repeat: no-repeat;
  height: 70%;
  width: 70%;
}

.vendorSpace {
  margin-left: 30px;
}

.dashImgDesig {
  margin-left: 100px;
}

.chartSpace {
  margin-right: 30px;
}

.pointerBtn {
  cursor: pointer;
  padding: 0px 20px 0px 60px;
}

.noDataImg {
  width: 90px;
  height: 70px;
}

.legalSpace {
  margin-left: 30px;
}

.primName {
  margin-left: 80px;
}

.vendorOffer {
  margin-left: 115px;
}

.vendorNum {
  margin-left: 110px;
}

.vendorStatus {
  margin-left: 60px;
}

.vendorOptions {
  margin-left: 70px;
}

.decorSpace {
  margin-left: 30px;
}

.poSpace {
  margin-left: 20px;
}

.soSpace {
  margin-left: 40px;
}

.qtySpace {
  margin-left: 50px;
}

.valueSpace {
  margin-left: 40px;
}

.StatusProdSpace {
  margin-left: 60px;
}

.radiusVendDes {
  border-radius: 50px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
}

.radiusVendDesIcon {
  border-radius: 50px;
  padding: 6px;
}

.modal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .back-close {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
  }

  .center-content {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 70%;
    height: 70vh;
    display: grid;
    overflow: scroll;
    align-items: center;
    justify-content: center;
    padding: 80px 0px 80px 0px;
  }

  .center-content-contact {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 8vh;
    width: 40%;
    height: 85vh;
  }

  .center-content-address {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 40%;
    height: 70vh;
    overflow: hidden;
  }

  .center-content-position {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 30vh;
    width: 40%;
    height: 35vh;
    overflow: scroll;
  }
}

.searchVendor {
  position: absolute;
  top: 10px;
  left: 9px;
}

.searchFill {
  border: $primary 1px solid !important;
  padding: 10px 10px 10px 27px !important;
  border-radius: 10px;
}

.radiusVendDesIcon {
  border-radius: 50px;
  padding: 6px;
}

.modal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .back-close {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
  }

  .center-content {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 70%;
    height: 70vh;
    display: grid;
    overflow: scroll;
    align-items: center;
    justify-content: center;
    padding: 80px 0px 80px 0px;
  }

  .center-content2 {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 70%;
    height: 70vh;
    display: grid;
    overflow: scroll;
    align-items: center;
    // justify-content: cent  er;
    padding: 0px 0px 10px 0px;
  }

  .center-content-contact {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 8vh;
    width: 40%;
    height: 85vh;
  }

  .center-content-contact2 {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 8vh;
    // width: 70%;
    height: 85vh;
  }

  .center-content-address {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 40%;
    height: 70vh;
  }

  .center-content-position {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 30vh;
    width: 40%;
    height: 35vh;
    overflow: scroll;
  }
}

.searchVendor {
  position: absolute;
  top: 10px;
  left: 9px;
}

.searchFill {
  border: $primary 1px solid !important;
  padding: 10px 10px 10px 27px !important;
  border-radius: 10px;
}

.radiusVendDesIcon {
  border-radius: 50px;
  padding: 6px;
}

.modal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .back-close {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
  }

  .center-content {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 70%;
    height: 70vh;
    display: grid;
    overflow: scroll;
    align-items: center;
    justify-content: center;
    padding: 80px 0px 80px 0px;
  }

  .center-content-contact {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 8vh;
    width: 40%;
    height: 85vh;
  }

  .center-content-address {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 40%;
    height: 70vh;
  }

  .center-content-position {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 30vh;
    width: 40%;
    height: 35vh;
    overflow: scroll;
  }
}

.searchVendor {
  position: absolute;
  top: 10px;
  left: 9px;
}

.searchFill {
  border: $primary 1px solid !important;
  padding: 10px 10px 10px 27px !important;
  border-radius: 10px;
}

.radiusVendDesIcon {
  border-radius: 50px;
  padding: 6px;
}

.modal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .back-close {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
  }

  .center-content {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 70%;
    height: 70vh;
    display: grid;
    overflow: scroll;
    align-items: center;
    justify-content: center;
    padding: 80px 0px 80px 0px;
  }

  .center-content-contact {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 8vh;
    width: 40%;
    height: 85vh;
  }

  .center-content-address {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 40%;
    height: 70vh;
  }

  .center-content-position {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 30vh;
    width: 40%;
    height: 35vh;
    overflow: scroll;
  }
}

.searchVendor {
  position: absolute;
  top: 10px;
  left: 9px;
}

.searchFill {
  border: $primary 1px solid !important;
  padding: 10px 10px 10px 27px !important;
  border-radius: 10px;
}

.radiusVendDesIcon {
  border-radius: 50px;
  padding: 6px;
}

.modal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .back-close {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
  }

  .center-content {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 70%;
    height: 70vh;
    display: grid;
    overflow: scroll;
    align-items: center;
    justify-content: center;
    padding: 80px 0px 80px 0px;
  }

  .center-content-contact {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 8vh;
    width: 40%;
    height: 85vh;
  }

  .center-content-address {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 40%;
    height: 70vh;
  }

  .center-content-position {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 30vh;
    width: 40%;
    height: 35vh;
    overflow: scroll;
  }
}

.searchVendor {
  position: absolute;
  top: 10px;
  left: 9px;
}

.searchFill {
  border: $primary 1px solid !important;
  padding: 10px 10px 10px 27px !important;
  border-radius: 10px;
}

.profileImg {
  width: 180px;
  height: 180px;
  object-fit: contain;
  border-radius: 20px;
}

.vendorDetSpace {
  margin-top: 80px !important;
}

.replyImg {
  width: 80px;
  height: 80px;
}

.linkImg {
  transform: rotate(45deg);
}

.radiusVendDesIcon {
  border-radius: 50px;
  padding: 6px;
}

.modal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .back-close {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
  }

  .center-content {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 70%;
    height: 70vh;
    display: grid;
    overflow: scroll;
    align-items: center;
    justify-content: center;
  }
}

.searchVendor {
  position: absolute;
  top: 10px;
  left: 9px;
}

.searchFill {
  border: $primary 1px solid !important;
  padding: 10px 10px 10px 27px !important;
  border-radius: 10px;
}

// Custom Btn start
.get-start-btn {
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  font-family: "f3";
  background: $primary;
}

// Custom Btn end

// Input Box start
.box {
  p {
    font-size: 19px;
    color: $primary1;
    font-family: "f2";
    margin-bottom: 5px;
  }

  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #fff;
    border-radius: 10px;
    // border: 4;
    // border-color: $primary1;
    padding: 0 20px;

    border-style: solid;
    border-width: 2px;
  }
}

// Upload img start
.upload-box {
  margin-bottom: 40px;
  width: 60%;
  justify-content: space-between;
}

.file-upload {
  display: flex;
  align-items: center;
  flex-direction: row;

  .file-icon {
    width: 400px;
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f7ff;
    border-radius: 30px;
    border: 0;

    // box-shadow:
    //   -10px -10px 30px 0px #f4f8ff,
    //   10px 10px 30px 0px rgba(199, 213, 239, 0.2),
    //   -10px -10px 10px 0px rgba(199, 213, 239, 0.4) inset,
    //   10px 10px 10px 0px rgba(255, 255, 255, 0.5) inset;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.placeholder_icon {
        width: 60px;
        height: 60px;
      }

      &.placeholder_icon1 {
        width: 400px;
        height: 230px;
      }
    }
  }

  .upload-btn {
    border-radius: 10px;
    margin-top: 0px;
    font-size: 16px;
    font-family: "f3";
    color: $primary;
    text-align: center;
    align-self: center;
    overflow: hidden;
  }

  .leble-cust {
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.arrowIcon {
  transform: rotate(90deg);
}

// Upload img end
// Input Box end
.mailImg {
  width: 150px;
  height: 150px;
}

.artworkImg {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.uploadImgLib {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.uploadImgLib {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.bannerVendor {
  background-image: url("../../img/In-the-News-02.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 70%;
  // height: 50% !important;
}

// Custom Btn start
.get-start-btn {
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  font-family: "f3";
  background: $primary;
}

// Custom Btn end

// Input Box start
.box {
  p {
    font-size: 19px;
    color: $primary1;
    font-family: "f2";
    margin-bottom: 5px;
  }

  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #fff;
    border-radius: 10px;
    // border: 4;
    // border-color: $primary1;
    padding: 0 20px;

    border-style: solid;
    border-width: 2px;
  }
}

// Upload img start
.upload-box {
  margin-bottom: 40px;
  width: 60%;
  justify-content: space-between;
}

.file-upload {
  display: flex;
  align-items: center;
  flex-direction: row;

  .file-icon {
    width: 400px;
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f7ff;
    border-radius: 30px;
    border: 0;

    // box-shadow:
    //   -10px -10px 30px 0px #f4f8ff,
    //   10px 10px 30px 0px rgba(199, 213, 239, 0.2),
    //   -10px -10px 10px 0px rgba(199, 213, 239, 0.4) inset,
    //   10px 10px 10px 0px rgba(255, 255, 255, 0.5) inset;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.placeholder_icon {
        width: 60px;
        height: 60px;
      }

      &.placeholder_icon1 {
        width: 400px;
        height: 230px;
      }
    }
  }

  .upload-btn {
    border-radius: 10px;
    margin-top: 0px;
    font-size: 16px;
    font-family: "f3";
    color: $primary;
    text-align: center;
    align-self: center;
    overflow: hidden;
    height: 230px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }

  .leble-cust {
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

// Upload img end
// Input Box end
.mailImg {
  width: 150px;
  height: 150px;
}

.artworkImg {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

// Custom Btn start
.get-start-btn {
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  font-family: "f3";
  background: $primary;
}

// Custom Btn end

// Input Box start
.box {
  p {
    font-size: 19px;
    color: $primary1;
    font-family: "f2";
    margin-bottom: 5px;
  }

  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #fff;
    border-radius: 10px;
    // border: 4;
    // border-color: $primary1;
    padding: 0 20px;

    border-style: solid;
    border-width: 2px;
  }
}

// Upload img start
.upload-box {
  margin-bottom: 40px;
  width: 60%;
  justify-content: space-between;
}

.file-upload {
  display: flex;
  align-items: center;
  flex-direction: row;

  .file-icon {
    width: 400px;
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f7ff;
    border-radius: 30px;
    border: 0;

    // box-shadow:
    //   -10px -10px 30px 0px #f4f8ff,
    //   10px 10px 30px 0px rgba(199, 213, 239, 0.2),
    //   -10px -10px 10px 0px rgba(199, 213, 239, 0.4) inset,
    //   10px 10px 10px 0px rgba(255, 255, 255, 0.5) inset;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.placeholder_icon {
        width: 60px;
        height: 60px;
      }

      &.placeholder_icon1 {
        width: 100%;
        height: 230px;
        object-fit: cover;
      }
    }
  }

  .upload-btn {
    border-radius: 10px;
    margin-top: 0px !important;
    font-size: 16px;
    font-family: "f3";
    color: $primary;
    text-align: center;
    align-self: center;
    overflow: hidden;
    height: 350px !important;
    // width: 400px;
    width: 100% !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    cursor: pointer;
  }

  .leble-cust {
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

// Upload img end
// Input Box end

// Product List Comp start
.prod-list-box-tl2 {
  .prod-list-box {
    background: $lt-blue2 !important;
    padding: 20px;
    border-radius: 20px;

    // height: 348px;
    // width: 247px;
    .prod-list-box-img {
      width: 100%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .btm-prod {
      h3 {
        font-size: 17px;
        margin-top: 10px;
        color: #000;
        font-family: "f4";
      }

      p {
        font-size: 16px;
      }

      button {
        width: 35px;
        height: 35px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin: auto 0 auto auto;
        color: #fff;
        font-size: 33px;
        font-family: "f5";
        background: #07679c;
      }
    }
  }
}

.custom-w-h {
  // height: 250px !important;
  width: 100% !important;
  height: 100px;
  border-radius: 20px;
  object-fit: cover !important;
  // object-fit: contain !important;
  align-self: center !important;
  justify-content: center;
  // border-radius: 50px !important;
  overflow: hidden;
}

.parag {
  font-family: "f2";
  color: $primary1;
  font-size: 15px;
}

// Product List Comp end
// Product Detaile start
.top_head_cust {
  background-color: #fdfbff;
  // background-color: red;
  border-radius: 20px;
}

.place-holde {
  ::placeholder {
    color: $primary1;
    text-align: center;
    font-size: 15px;
    font-family: "f3";
  }
}

.cp {
  cursor: pointer;
}

// Product Detaile end
.mailImg {
  width: 150px;
  height: 150px;
}

.artworkImg {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

// Custom Btn start
.get-start-btn {
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  font-family: "f3";
  background: $primary;
}

// Custom Btn end

// Input Box start
.box {
  p {
    font-size: 19px;
    color: $primary1;
    font-family: "f2";
    margin-bottom: 5px;
  }

  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #fff;
    border-radius: 10px;
    // border: 4;
    // border-color: $primary1;
    padding: 0 20px;

    border-style: solid;
    border-width: 2px;
  }
}

// Upload img start
.upload-box {
  margin-bottom: 40px;
  width: 60%;
  justify-content: space-between;
}

.file-upload {
  display: flex;
  align-items: center;
  flex-direction: row;

  .file-icon {
    width: 350px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f7ff;
    border-radius: 30px;
    border: 0;

    // box-shadow:
    //   -10px -10px 30px 0px #f4f8ff,
    //   10px 10px 30px 0px rgba(199, 213, 239, 0.2),
    //   -10px -10px 10px 0px rgba(199, 213, 239, 0.4) inset,
    //   10px 10px 10px 0px rgba(255, 255, 255, 0.5) inset;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.placeholder_icon {
        width: 60px;
        height: 60px;
      }

      &.placeholder_icon1 {
        width: 400px;
        height: 230px;
      }
    }
  }

  .upload-btn {
    border-radius: 10px;
    margin-top: 0px;
    font-size: 16px;
    font-family: "f3";
    color: $primary;
    text-align: center;
    align-self: center;
    overflow: hidden;
  }

  .leble-cust {
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

// Upload img end
// Input Box end

// Table Btn Size start
.num-cls-btn {
  width: 92px;
  height: 45px;
  border-radius: 10px;
  background: #ffffff;
  border-style: solid;
  border-width: 2px;
  border-color: $primary;
}

.num-cls-btn2 {
  width: 60px;
  height: 40px;
  border-radius: 10px;
  background: #ffffff;
  border-style: solid;
  border-width: 2px;
  border-color: $primary;
}

// Table Btn Size end

// Toggle btn start
.row {
  display: table-row;
}

.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 200px;
  height: 140px;
  box-sizing: border-box;
}

.button-cover {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.yes,
.no {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  // top: 50%;
  width: 60px;
  height: 30px;
  overflow: hidden;
}

.button.r,
.button.r .no {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.yes {
  z-index: 2;
}

.no {
  width: 100%;
  background-color: $primary1;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
.button-1 .yes:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 1px;
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  color: $primary;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 9px 4px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.button-1 .checkbox:checked+.yes:before {
  content: "";
  left: 30px;
  border-style: solid;
  border-width: 1px;
}

.button-1 .checkbox:checked~.no {
  // background-color: $primary1;
  background-color: $primary;
}

.button-1 .yes,
.button-1 .yes:before,
.button-1 .no {
  transition: 0.3s ease all;
}

// Toggle btn end

// Carocle start

// Carocle end

.ani_viewope {
  display: block;
  transition: 0.5s all linear;
  opacity: 1;
}

.ani_viewclose {
  display: none;
  opacity: 0;
  transition: 0.5s all linear;
}

.uploadImgLib {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.uploadImgLib {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.bannerVendor {
  background-image: url("../../img/In-the-News-02.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 70%;
  // height: 50% !important;
}

.drop-down {
  position: relative;
}

.submenu_cont_1 {
  transition: ease 0.5s;
  display: none;
}

.submenu_1 {
  display: block;
  position: absolute;
  background: #fff;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: $primary;
  // top: 0px;
  height: 260px !important;
  transition: ease 0.5s;
}

.submenu_2 {
  display: block;
  position: absolute;
  background: #fff;
  width: 256px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: #07679c;
  height: 377px;
  transition: ease 0.5s;
  right: 0;
  left: -11px;
}

.submenu_child_2 {
  position: absolute;
  background: #fff;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: $primary;
  height: 260px;
  overflow: hidden;
  // top: 80px;
  left: 200px;
  z-index: 100;
  display: none;
  transition: ease 0.7s;
}

.submenu_child_1 {
  display: block;
  position: absolute;
  background: #fff;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: $primary;
  left: 290px;
  z-index: 100;
  transition: ease 0.7s;
}

.submenu_1 .hover-cust:hover {
  background-color: $soft-gray1;
}

.submenu_2 .hover-cust:hover {
  background-color: $soft-gray1;
}

.drop_down {
  position: absolute;
  // top: 10px;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: end;
  overflow: hidden;
  // right: 10px;
  top: 0;
  right: 10px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
}

.drop_down2 {
  position: absolute;
  // top: 10px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: end;
  overflow: hidden;
  // right: 10px;
  top: 0;
  right: 10px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
}

.add_icon {
  position: absolute;
  // top: 10px;
  // width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: end;
  overflow: hidden;
  // right: 10px;
  top: 0;
  right: 10px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
}

.add {
  input {
    padding-right: 40px !important;
  }
}

.p-cust {
  padding-right: 35px !important;
}

// drop Down End
.uploadImgLib {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.uploadImgLib {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.bannerVendor {
  background-image: url("../../img/In-the-News-02.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 70%;
  // height: 50% !important;
}

.cust-tab-style {
  background-color: $primary !important;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  transition: all ease 0.2s;
}

.tranc {
  transition: all ease 0.2s;
}

.rk {
  border-style: solid;
  border-color: $primary;
  border-bottom-width: 5px;
  border-top-width: 0px;
  border-right: 0px;
  border-left: 0px;
}

// btn end

.actionPopup {
  position: absolute;
  bottom: -33px;
  right: -15px;
  background-color: white;
}

.actionDetailPopup {
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: white;
}

// Menu Background start
.invis {
  visibility: hidden;
}

.vis {
  visibility: visible;
}

.invisible-cont2 {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: rgba(255, 0, 0, 0.094);
}

// Menu Background end

.actionPopup {
  position: absolute;
  bottom: -33px;
  right: -15px;
  background-color: white;
}

.actionDetailPopup {
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: white;
}

.addScrollPop {
  height: 100%;
}

// image List slide
.product-image {
  width: 400px;
  position: relative;
  margin-bottom: 10px;
  box-shadow: inset 0 0 100px #000;
  -webkit-box-shadow: inset 0 0 100px #000;
  -moz-box-shadow: inset 0 0 100px #000;
}

.edit-image {
  top: 0;
  z-index: 999;
}

.edit-image li,
.image-controls li {
  height: 100%;
  display: block;
  width: 33%;
  float: left;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  color: #fff;
}

li {
  list-style: none;
}

.product-image img {
  width: 100%;
  display: block;
}

.image-controls {
  bottom: 0;
}

.edit-image,
.image-controls {
  position: absolute;
  left: 0;
  height: 30px;
  width: 100%;
  background: $primary1;
  // border: 1px solid;
}

.edit-image li,
.image-controls li {
  height: 100%;
  display: block;
  width: 100%;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  color: #fff;
}

.product-image {
  list-style: none;
  text-align: center;
  transition: all ease 0.5s;
}

.image-pro {
  width: 80px !important;
  height: 80px !important;
  cursor: pointer;
}

.image-show {
  opacity: 0;
  transition: all ease 0.5s;
}

.image-hide {
  opacity: 1;
  transition: all ease 0.5s;
}

// slide image
.slide_image {
  // width: 500px;
  width: 350px;
  height: 350px;
  // background-color: red;
  border-radius: 20px;
  border-color: $primary;
  border-style: solid;
  border-width: 2px;
}

.imagr_box {
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 20px;
}

.add_img {
  width: 70px;
  height: 70px;
  background-color: $lt-blue1;
  border-radius: 10px;
}

.add_img2 {
  width: 70px;
  height: 70px;
  background-color: $lt-blue1;
  border-radius: 10px;
}

.border_img {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

// slide image
// image List slide

.request-text {
  color: rgb(0, 57, 246);
}

.Inprocess-text {
  color: orange;
}

.Accept-text {
  color: green;
}

.Regected-text {
  color: red;
}

// Dissabled Input Bg Color Change
// input[type="text"][disabled] {
//   background-color: #fff;
//   color: #000;
// }

// textarea[type="text"][disabled] {
//   background-color: #fff;
//   color: #000;
// }
// input[type="button"] {
//   background-color: #fff;
// }
textarea.text {
  background-color: #fff;
  resize: none;
  // box-decoration-break: slice;
}

// Dissabled Input Bg Color Change
.img_size {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.border_cust {
  border-bottom-width: 2px;
  padding-bottom: 10px;
  border-color: $lt-blue1;
  border-style: solid;
}

.product_img {
  height: 400px;
  width: 400px;
  object-fit: contain;
}

.cust_t {
  width: 500px !important;
  overflow: scroll !important;
}

// Upload image Start
.img_up_cont {
  background-color: #f3f7ff;
  height: 400px;
  width: 400px;
  border-radius: 20px;
  // display: flex;
}

// Upload image end

// After Click Action
.after_click:visited {
  color: red;
}

a {
  color: black;
}

a:active {
  color: blue;
}

a[tabindex]:focus {
  color: blue;
  outline: none;
}

// Hover Text Effect start
.hover_img_cont {
  position: relative;
  overflow-y: hidden;

  .hover_image {
    display: block;
    width: 100%;
    height: auto;
  }

  .hover_cont {
    position: absolute;
    bottom: 0% !important;
    height: 100%;
    width: 100%;
    opacity: 0;
    // display: none;
    visibility: hidden;
    top: 100% !important;
    transition: 0.5s ease;
    background-color: #0000007e;
  }

  p {
    color: red;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  p:hover {
    // color: rgb(43, 119, 249) !important;
  }
}

.hover_img_cont:hover .hover_cont {
  visibility: visible;
  top: 0 !important;
  opacity: 1;
  transition: all ease 0.5s;
}

// Hover Text Effect end

.img_up_cont2 {
  background-color: #f3f7ff;
  height: 350px;
  width: 350px;
  border-radius: 20px;
  // display: flex;
}

.cust_dis_h {
  height: 135px !important;
  overflow: scroll !important;
}

.btn_border {
  border: $primary;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
  transition: 0.5s;
}

// .btn_border:hover {
//   color: #fff !important;
//   background-color: $primary !important;
//   transition: 0.5s;
//   .icon_bg {
//     color: #fff !important;
//   }
//   p {
//     color: #fff;
//   }
// }
.icon_posi {
  right: 0 !important;
}

.image-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11111 !important;

  >div {
    padding: 30px;
    width: 80%;
    min-height: 50%;
    background-color: #fff;
    border-radius: 20px;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    // box-shadow: inset 0 0 10px $primary1;
  }

  ::-webkit-scrollbar-thumb {
    // background: $primary1;
    box-shadow: inset 0 0 10px $primary1;
    border-radius: 10px;
  }
}

.for_hover {
  position: relative;
}

.in_hover {
  position: absolute;
  opacity: 0;
  top: 0%;
}

.for_hover:hover .in_hover {
  svg {
    width: 23%;
  }

  opacity: 1;
}

// Image Uploade start
.uplode_img_btn {
  input[type="file"] {
    font-size: 0;
    width: auto;
    z-index: 150;
  }

  ::file-selector-button {
    font-size: initial;
  }
}

// Image Uploade end

// Input box icon

.box_icon {
  position: absolute;
  top: 238px;
  left: 49px;
  height: 40px;
  width: 40px;
  background-color: $lt-blue1;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  // border-start-end-radius: 10px;
}

.cust_table {
  table {
    width: 100%;

    thead {
      tr {
        th {
          // background-color: red;
          white-space: nowrap !important;
          padding-right: 30px !important;
          padding-left: 30px !important;
          padding-top: 20px;
          padding-bottom: 20px;
        }

        td {
          // background-color: red;
          white-space: nowrap !important;
          padding-right: 30px !important;
          padding-left: 30px !important;
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }
}

// Line
.line {
  height: 0.3px;
  background-color: #dddddd;
}

.bg_today {
  -webkit-box-shadow: inset -2px 2px 4px 3px #fff,
    10px 10px 13px -5px rgba(161, 183, 219, 0.44);
}

.scroll_view {
  height: 400px;
  overflow-y: scroll;
}

// overview Style start
.img_size3 {
  width: 300px !important;
  height: 300px !important;
}

.sm_img_size {
  height: 60px;
  width: 60px;
  border-radius: 3px;
  object-fit: contain;
}

.sm_img_size2 {
  height: 100px;
  width: 100px;
  border-radius: 3px;
  object-fit: contain;
}

.image_hover_eff:hover .down_icon {
  visibility: visible !important;
  transition: all 0.3s;
}

// overview Style end

.alert-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 200 !important;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  >div {
    padding: 30px;
    // width: 50%;
    min-height: 30%;
    background-color: #fff;
    border-radius: 20px;
  }

  .customPopupDesign {
    width: 400px;
    background-color: white;
    padding: 25px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: grid;
  }
}

.table-design-new {
  th {
    font-family: "f1" !important;
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    padding: 10px;
  }
}

.tot-cont {
  color: #00bf7c;
  font-weight: 600;
}

.search_new_design {
  border: 1px solid #dee2e6;
  padding: 5px;
  border-radius: 10px;
  align-items: center;

  .searchiConImg {
    width: 23px;
    height: 23px;
    margin-top: 5px;
  }
}

.card_info {
  position: relative;

  .info_card {
    opacity: 0;
    position: absolute;
    width: 200px;
    align-items: center;
    display: flex;
    text-align: center;
    border: #efefef 1px solid;
    box-shadow: 7px 7px 15px -1px #d1d6eb;
    -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb;
    /* margin-bottom: 6px; */
    justify-content: center;
    background: #ffffff;
    border-radius: 10px;
    padding: 5px;
    // bottom: 30px;
    top: 30px;
    margin-left: -60px;
  }
}

.card_info:hover {
  .info_card {
    opacity: 1;
  }
}

// TOLLTIP BOOTSTARP CUSTOM DESIGN

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #000;
  --bs-tooltip-bg: #ffffff;
  // box-shadow: 7px 7px 15px -1px #d1d6eb;
  box-shadow: 0 0.8rem 2rem rgba(0, 0, 0, 0.1490196);
  /* -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb; */
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 1;
  border: #efefef 1px solid;
  border-radius: 20px;
}

/* PopUp */
.popup-wrap {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-inner_box {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 30px;

  input {
    border: 1px solid #07679c;
  }
}

.add_event_cat_btn {
  border-radius: 10px;
  background: #07679c;
  padding: 4px 10px;
  color: #fff;
  font-size: 16px;
}

.popup_close {
  top: 4%;
  right: 4%;
  color: #07679c;
  font-weight: 700;
  background: #ffffff;
  border-radius: 30px !important;
  box-shadow: -2px -2px 5px 0px #b2c3e133 !important;
}

.popup_close:focus {
  animation-timing-function: ease-out;
  animation-duration: 300ms;
}

.table_img {
  width: 60px;
  height: 60px;
}

.table_img_container {
  margin: 0 auto;
  width: 30%;
}

.w-20 {
  width: 20%;
}

.preset_count {
  color: #00bf7c;
}

.tag_filter {
  font-size: 16px;

  button {
    color: #07679c;
    font-size: 14px;
    border: none;
    background-color: #fff;
  }
}

.priority {
  width: 110px;
  color: #fff;
  border-radius: 5px;

  .icon {
    width: 20px;
    height: 20px;
    color: #fff;
  }

  button {
    padding: 2px;
  }

  button {
    padding: 2px;
  }

  span {
    margin-top: 4px;
    font-size: 14px;
  }
}

.danger {
  background: #ff0000;
}

.success {
  background: #26d497;
}

.danger {
  background: #ff0000;
}

.success {
  background: #26d497;
}

.custom-btn {
  top: 45%;
  right: 0%;
}

.pointer {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.down-icon {
  top: 56%;
  right: 3%;
}

// search input
.border-search {
  border: 1px solid #d3d3d3;
  padding: 4px;
  border-radius: 10px;
}

.add-files-overiew-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  // position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 10000 !important;
  justify-content: center;

  >div {
    padding: 30px;
    width: 80%;
    height: 90vh;
    background-color: #fff;
    border-radius: 20px;

    // overflow-y: scroll !important;
    .layer_cont {
      height: 100%;
      overflow: scroll;
    }
  }

  .over_files_pop {
    button {
      border: none;
      background-color: #fff;
      font-weight: 500;
      border-top: 0;
      color: gray;
      border-left: 0;
      border-right: 0;
      width: 7.4rem;
      font-weight: 900;
      height: 3.5rem;
    }

    .btn_overview_line {
      color: #000;
      border-bottom: 4px solid $primary;
    }
  }

  .files_pop_pro {
    margin-top: 3%;

    .over_img {
      border: 2px solid white;
      border-radius: 6px;
      transition: 0.3s ease;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      width: 180px;
      height: 180px;
      position: relative;

      img {
        width: 20%;
      }

      .files_btn {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;

        button {
          border: none;
          background-color: #07679c;
          color: white;
          // font-size: 12px;
          // font-weight: 500;
          // width: 3.3rem;
          // height: 1.5rem;
          border-radius: 5px;
        }
      }
    }

    .over_img:hover .files_btn {
      opacity: 1;
    }
  }
}

.border_click_cust {
  border: 2px $primary solid;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  color: $primary;
}

.errtext {
  display: flex;
  justify-content: end;
  color: red;
}

.err-input_log {
  // position: absolute;
  // right: 0;
  font-size: 12px !important;
  font-family: "f2";
  color: red;
}

.svg_log {
  width: 18px !important;
  color: red;
}

.popup-wrap_new {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-innerbox_new {
  background-color: rgb(25, 28, 36);
  width: 38%;
  height: 30%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 16px;
    font-family: "f2";
    text-align: center;
    color: #fff;
  }

  div {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;

    button {
      margin: 0;
      border: 0;
      padding: 6px 20px;
      border-radius: 6px;
      font-size: 16px;
      font-family: "f3";
      color: #fff;

      &:first-of-type {
        background-color: #07679c !important;
      }

      &:last-of-type {
        background-color: #787b7f;
      }
    }
  }
}

.popup-pass_new {
  position: fixed !important;
  top: calc(100vh - 300px) !important;
}

.add-category-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 150 !important;
  align-items: center;
  justify-content: center;

  >div {
    padding: 30px;
    width: 50%;
    min-height: 50%;
    background-color: #fff;
    border-radius: 20px;
  }
}

.add-content {
  height: 40%;
}